import React, { useState, useEffect } from 'react';
import './Form.css';
import { useLocation } from 'react-router-dom';
import { baseEndpoint } from '../../../API/endpoints';
// import dataset from './dataset';
// const dataset =[
//   {
//     "class": "6",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "Poorvi",
//             "chapters": [
//               "Culture and Tradition",
//               "Friendship",
//               "Change of Heart",
//               "Neem Baba",
//               "Fables and Folk Tales"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Curiosity",
//             "chapters": [
//               "Methods of Separation in Everyday Life",
//               "Beyond Earth",
//               "Mindful Eating: A Path to a Healthy Body",
//               "Materials Around Us",
//               "Living Creatures: Exploring their Characteristics",
//               "Exploring Magnets",
//               "A Journey through States of Water",
//               "The Wonderful World of Science",
//               "Nature’s Treasures",
//               "Measurement of Length and Motion",
//               "Temperature and its Measurement",
//               "Diversity in the Living World"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Exploring Society India and Beyond",
//             "chapters": [
//               "Grassroots Democracy – Part 1 Governance",
//               "The Beginnings of Indian Civilisation",
//               "Grassroots Democracy – Part 2 Local Government in Rural Areas",
//               "Timeline and Sources of History",
//               "Landforms and Life",
//               "The Value of Work",
//               "Locating Places on the Earth",
//               "Economic Activities Around Us",
//               "Unity in Diversity, or ‘Many in the One’",
//               "Grassroots Democracy – Part 3 Local Government in Urban Areas",
//               "India, That Is Bharat",
//               "Family and Community",
//               "Oceans and Continents",
//               "India’s Cultural Roots"
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "class": "7",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "Honey Comb",
//             "chapters": [
//               "Quality",
//               "Expert Detectives",
//               "Three Questions",
//               "Gopal and the Hilsa-fish",
//               "The Ashes That Made Trees Bloom",
//               "A Gift of Chappals",
//               "A Homage to our Brave Soldiers"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Science",
//             "chapters": [
//               "Motion and Time",
//               "Nutrition in Animals",
//               "Light",
//               "Heat",
//               "Physical and Chemical Changes",
//               "Nutrition in Plants",
//               "Respiration in Organisms",
//               "Forests: Our Lifeline",
//               "Acids, Bases and Salts",
//               "Electric Current and its Effects",
//               "Wastewater Story",
//               "Transportation in Animals and Plants",
//               "Reproduction in Plants"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Our Environment",
//             "chapters": [
//               "Inside our Earth",
//               "Environment",
//               "Human Environments  Interactions: The Tropical and  The Subtropical Region",
//               "Air",
//               "Life in the Desert",
//               "Water",
//               "Our Changing Earth"
//             ]
//           },
//           {
//             "title": "Our Pasts 2",
//             "chapters": [
//               "The Making of Regional Cultures",
//               "Delhi: 12th to 15th Century",
//               "Devotional Paths to the Divine",
//               "Introduction Tracing Changes Through a Thousand Years",
//               "Tribes , Nomads and Settled Communities",
//               "The Mughals (16th TO 17th Century)",
//               "Kings and Kingdoms",
//               "Eighteenth Century Political Formations"
//             ]
//           },
//           {
//             "title": "Social and Political Life 2",
//             "chapters": [
//               "On Equality",
//               "Growing up as Boys and Girls",
//               "How the State Government Works",
//               "A Shirt in the Market",
//               "Understanding Media",
//               "Role of the Government in Health",
//               "Market Around Us",
//               "Women Change the World"
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "class": "8",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "It so Happend",
//             "chapters": [
//               "The fight",
//               "The treasure within",
//               "Princess September",
//               "The Selfish Giant",
//               "Ancient Education System of India",
//               "Jalebis",
//               "Children at work",
//               "How the Camel got his hump"
//             ]
//           },
//           {
//             "title": "Honeydew",
//             "chapters": [
//               "A Visit to Cambridge",
//               "The Best Christmas Present in the World",
//               "A Short Monsoon Diary",
//               "Glimpses of the Past",
//               "This is Jody’s Fawn",
//               "The Summit Within",
//               "The Tsunami",
//               "Bepin Choudhury’s Lapse of Memory"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Science",
//             "chapters": [
//               "Chemical effects of Electric Current",
//               "Microorganisms : Friend and Foe",
//               "Combustion and Flame",
//               "Reaching the Age of Adolescence",
//               "Light",
//               "Sound",
//               "Reproduction in Animals",
//               "Crop Production and Management",
//               "Force and Pressure",
//               "Fiction",
//               "Conservation of Plants and Animals",
//               "Coal and Petroleum",
//               "Some Natural Phenomena"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Resource and Development",
//             "chapters": [
//               "Human Resources",
//               "Industries",
//               "Agriculture",
//               "Land, Soil, Water, Natural Vegetation and Wildlife Resources",
//               "Resources"
//             ]
//           },
//           {
//             "title": "Our Past 3",
//             "chapters": [
//               "Women, Caste and Reform",
//               "The Making of the National Movement: 1870s–1947",
//               "Tribals, Dikus and the Vision of a Golden Age",
//               "Ruling the Countryside",
//               "Introduction: How, When and Where",
//               "From Trade to Territory",
//               "When People Revolt",
//               "Civilising the “Native”, Educating the Nation"
//             ]
//           },
//           {
//             "title": "Social and  Political Life",
//             "chapters": [
//               "Confronting Marginalisation",
//               "Understanding Secularism",
//               "Law and Social Justice",
//               "Parliament and the Making of Laws",
//               "The Indian Constitution",
//               "Understanding Marginalisation",
//               "Judiciary",
//               "Public Facilities"
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "class": "9",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "Beehive",
//             "chapters": [
//               "If I Were You",
//               "Kathmandu",
//               "A Truly Beautiful Mind",
//               "The Snake and the Mirrror",
//               "The Little Girl",
//               "The Fun They Had",
//               "Reach for the Top",
//               "The Sound of Music",
//               "My Childhood"
//             ]
//           },
//           {
//             "title": "Moments Supplymentry Reader",
//             "chapters": [
//               "The Last Leaf",
//               "The Lost Child",
//               "The Adventures of Toto",
//               "The Happy Prince",
//               "Weathering the Storm in Ersama",
//               "A House Is Not a Home",
//               "The Beggar",
//               "Iswaran the Storyteller",
//               "In the Kingdom of Fools"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Information and Communication Technology",
//         "titles": [
//           {
//             "title": "Information and Communication Technology",
//             "chapters": [
//               "Getting Connected Internet",
//               "Fun with Logic",
//               "Creating Audio Video Communication",
//               "Creating Textual Communication",
//               "Creating Visual Communication",
//               "Presenting Ideas",
//               "Safety and Security in the Cyber World",
//               "Introduction to ICT"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Science",
//             "chapters": [
//               "Gravitation",
//               "Natural Resources",
//               "Improvement in Food Resources",
//               "Force and Laws of Motion",
//               "Sound",
//               "Atoms and Molecules",
//               "Structure of the Atom",
//               "Matter in our Surroundings",
//               "Is Matter around us Pure?",
//               "Tissues",
//               "Motion",
//               "Work and Energy",
//               "Why do we fall ill?",
//               "The Fundemental unit of Life"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Health and Physical Education",
//         "titles": [
//           {
//             "title": "Health and Physical Education",
//             "chapters": [
//               "Physical Education",
//               "Waste Management",
//               "Adolescent Health Services (AFHS)",
//               "Physical Fitness",
//               "Personality Development Through Yoga",
//               "Social Health",
//               "Growing up with Confidence",
//               "Diet for Healthy Living",
//               "Health and Diseases",
//               "Sports Training",
//               "Individual Sports",
//               "Team Games",
//               "First Aid and Safety",
//               "Ethics in Sports"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Economics",
//             "chapters": [
//               "Food Security in India",
//               "Poverty as a Challenge",
//               "The Story of Village Palampur",
//               "People as Resource"
//             ]
//           },
//           {
//             "title": "Democratic Politics",
//             "chapters": [
//               "Democratic Rights",
//               "Electoral Politics",
//               "Constitutional Design",
//               "What is Democracy? Why democracy?",
//               "Working  of Institutions"
//             ]
//           },
//           {
//             "title": "India and the Contemporary World 1",
//             "chapters": [
//               "Pastoralists in the Modern World",
//               "Socialism in Europe and the Russian Revolution",
//               "The French Revolution",
//               "Nazism and the Rise of  Hitler",
//               "Forest Society and Colonialism"
//             ]
//           },
//           {
//             "title": "Contemporary India",
//             "chapters": [
//               "India-Size and Location",
//               "Climate",
//               "Drainage",
//               "Natural Vegetation and Wildlife",
//               "Population",
//               "Physical Features of India"
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "class": "10",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "Footprints without Feet",
//             "chapters": [
//               "Footprints without Feet",
//               "A Question of Trust",
//               "A Triumph of Surgery",
//               "The Book That Saved the Earth",
//               "Bholi",
//               "The Thief's Story",
//               "The Necklace",
//               "The Making of a Scientist",
//               "The Midnight Visitor"
//             ]
//           },
//           {
//             "title": "First Flight",
//             "chapters": [
//               "The Proposal",
//               "Nelson Mandela: Long Walk to Freedom",
//               "A Letter to God",
//               "Madam Rides the Bus",
//               "Two Stories about Flying",
//               "From the Diary of Anne Frank",
//               "The Sermon at Benares",
//               "Glimpses of India",
//               "Mijbil the Otter"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Science",
//             "chapters": [
//               "Light – Reflection and Refraction",
//               "Chemical Reactions and Equations",
//               "How do Organisms Reproduce?",
//               "Our Environment",
//               "Metals and Non-metals",
//               "The Human Eye and the Colourful World",
//               "Acids, Bases and Salts",
//               "Control and Coordination",
//               "Electricity",
//               "Magnetic Effects of Electric Current",
//               "Carbon and its Compounds",
//               "Heredity",
//               "Life Processes"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Health and Physical Education",
//         "titles": [
//           {
//             "title": "Health and Physical Education",
//             "chapters": [
//               "Effects of Physical Activities on Human Body",
//               "Growth and Development during Adolescence",
//               "Team Games and Sports 2",
//               "Team Games and Sports 1",
//               "Social Health",
//               "Individual Games and Sports 1",
//               "Dietary Considerations and Food Quality",
//               "Yoga for Healthy Living",
//               "Physical Education : Relationship with other Subjects",
//               "Agencies and Awards Promoting Health,Sports and Yoga",
//               "Individual Games and Sports 2",
//               "Healthy Community Living",
//               "Safety Measures for Healthy Living"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Democratic Politics",
//             "chapters": [
//               "Political Parties",
//               "Power-sharing",
//               "Gender, Religion and Caste",
//               "Outcomes of Democracy",
//               "Federalism"
//             ]
//           },
//           {
//             "title": "Understanding Economic Development",
//             "chapters": [
//               "Globalisation and The India Economy",
//               "Sectors of the  Indian Economy",
//               "Money and Credit",
//               "Consumer Rights",
//               "Development"
//             ]
//           },
//           {
//             "title": "India and the Contemporary World-2",
//             "chapters": [
//               "The Making of a Global World",
//               "The Age of Industrialisation",
//               "Nationalism in India",
//               "The Rise of Nationalism in Europe",
//               "Print Culture and the Modern World"
//             ]
//           },
//           {
//             "title": "Contemporary India",
//             "chapters": [
//               "Water Resources",
//               "Minerals and Energy Resources",
//               "Manufacturing Industries",
//               "Lifelines of National Economy",
//               "Agriculture",
//               "Resources and Development",
//               "Forest and Wildlife Resources"
//             ]
//           }
//         ]
//       }
//     ]
//   }
// ]

const Formpage = ({ onAnswersToggle, onFormSubmit }) => {
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [tempSelectedChapters, setTempSelectedChapters] = useState([]);
  const [numQuestions, setNumQuestions] = useState(10);
  const [difficulty, setDifficulty] = useState('medium');
  const [qType, setQType] = useState('Short Answers');
  const [isClassOpen, setIsClassOpen] = useState(false);
  const [isSubjectOpen, setIsSubjectOpen] = useState(false);
  const [hoveredSubject, setHoveredSubject] = useState(null);
  const [showChapterSelect, setShowChapterSelect] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [isQTypeOpen, setIsQTypeOpen] = useState(false);
  const location = useLocation();
  const isAssignment = location.pathname.includes('assignment');
  const [dataset, setDataset] = useState([]);
  useEffect(() => {
    
    fetchDataFromBackend();
  }, []);
  const fetchDataFromBackend = async () => {
    try {
        // const response = await fetch('http://localhost:8080/dataset/dataset_access');
        const response = await fetch(`${baseEndpoint}/dataset/dataset_access`);
        const data = await response.json();
        console.log('Fetched data:', data); // Log the data to check its structure
        // setSelectedClass(data);
        setDataset(data);
        console.log(data)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  const handleTitleClick = (subject, title) => {
    setSelectedSubject(subject);
    setSelectedTitle(title);
    setIsSubjectOpen(false);
    setSelectedChapters([]);
    setTempSelectedChapters([]);
    setShowChapterSelect(false);
  };

  const handleClassClick = (classNumber) => {
    setSelectedClass(classNumber);
    setIsClassOpen(false);
    // Clear subject, title and chapters when changing class
    setSelectedSubject('');
    setSelectedTitle('');
    setSelectedChapters([]);
    setTempSelectedChapters([]);
    setShowChapterSelect(false);
  };

  const handleClassDropdownClick = () => {
    setIsClassOpen(!isClassOpen);
    // Close other dropdowns
    setIsSubjectOpen(false);
    setShowChapterSelect(false);
    setIsQTypeOpen(false);
  };

  const handleSubjectDropdownClick = () => {
    if (selectedClass) {
      setIsSubjectOpen(!isSubjectOpen);
      // Close other dropdowns
      setIsClassOpen(false);
      setShowChapterSelect(false);
      setIsQTypeOpen(false);
    }
  };

  const handleChapterFieldClick = () => {
    if (selectedSubject) {
      setShowChapterSelect(!showChapterSelect);
      // Close other dropdowns
      setIsClassOpen(false);
      setIsSubjectOpen(false);
      setIsQTypeOpen(false);
      if (!showChapterSelect) {
        setTempSelectedChapters([...selectedChapters]);
      }
    }
  };

  const handleChapterChange = (chapter) => {
    const newTempSelectedChapters = tempSelectedChapters.includes(chapter)
      ? tempSelectedChapters.filter(ch => ch !== chapter)
      : [...tempSelectedChapters, chapter];
    setTempSelectedChapters(newTempSelectedChapters);
  };

  const handleApplyChapters = (e) => {
    if (e) e.stopPropagation();
    setSelectedChapters([...tempSelectedChapters]);
    setShowChapterSelect(false);
  };

  const handleGenerateClick = () => {
    if (!selectedClass || !selectedSubject || !selectedTitle || selectedChapters.length === 0) {
      alert('Please fill in all required fields');
      return;
    }

    const formData = {
      standard: selectedClass,
      subject: selectedSubject,
      title: selectedTitle,
      file_name: selectedChapters,
      number: numQuestions,
      complexity: difficulty,
      topic: "",
      ...(isAssignment && { q_type: qType })
    };

    if (onFormSubmit) {
      onFormSubmit(formData);
    }
  };

  const handleQTypeClick = (e) => {
    e.stopPropagation();
    setIsQTypeOpen(!isQTypeOpen);
    // Close other dropdowns
    setIsClassOpen(false);
    setIsSubjectOpen(false);
    setShowChapterSelect(false);
  };

  useEffect(() => {
    const questionsSlider = document.querySelector('.questions-slider');
    const difficultySlider = document.querySelector('.difficulty-slider');
    
    if (questionsSlider) {
      const percent = (numQuestions / 20) * 100;
      questionsSlider.style.background = `linear-gradient(to right, #4a72ff 0%, #4a72ff ${percent}%, #e0e0e0 ${percent}%, #e0e0e0 100%)`;
    }
    
    if (difficultySlider) {
      const value = difficulty === 'easy' ? 0 : difficulty === 'medium' ? 1 : 2;
      const percent = (value / 2) * 100;
      difficultySlider.style.background = `linear-gradient(to right, #4a72ff 0%, #4a72ff ${percent}%, #e0e0e0 ${percent}%, #e0e0e0 100%)`;
    }
  }, [numQuestions, difficulty]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.custom-select')) {
        setIsQTypeOpen(false);
        setIsClassOpen(false);
        setIsSubjectOpen(false);
        setShowChapterSelect(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Pre-fill form details from Mainform
    if (location.state) {
      const { 
        selectedClass,
        selectedSubject,
        selectedTitle,
        selectedChapters,
        numberOfQuestions = 10,
        difficulty = 'medium',
        type 
      } = location.state;

      // Set state for class dropdown
      setSelectedClass(selectedClass);

      setSelectedSubject(selectedSubject);
      setSelectedTitle(selectedTitle);
      setSelectedChapters(selectedChapters||[]);
      setNumQuestions(numberOfQuestions);
      setDifficulty(difficulty);

      // Set Q Type for assignments
      if (type === 'assignment') {
        setQType('short answers');
      }
    }
  }, [location.state]);

  return (
    <div className={`container ${isMinimized ? 'minimized' : ''}`}>
      <div 
        className="minimize-icon" 
        onClick={() => setIsMinimized(!isMinimized)}
        title={isMinimized ? "Maximize" : "Minimize"}
      >
        <div className={`formside-icon-container  ${isMinimized ? 'minimized' : ''}`}>
            <img src="/images/Group 1261153857.svg" alt="" className="formside-icon-img" />
        </div>
      </div>
      <div className="form-group">
        <div className="dropdown-container">
          <div 
            className={`custom-select ${isClassOpen ? 'open' : ''}`}
            onClick={handleClassDropdownClick}
          >
            <div className="formselect-header">
              {selectedClass ? `Class ${selectedClass}` : 'Select Class'}
              <span className="arrow">▼</span>
            </div>
            {isClassOpen && (
              <div className="dropdown-list">
                <div className="dropdown-scroll">
                  {dataset.map(item => (
                    <div 
                      key={item.class} 
                      className="class-option"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClassClick(item.class);
                      }}
                    >
                      Class {item.class}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dropdown-container">
          <div 
            className={`custom-select ${!selectedClass ? 'disabled' : ''} ${isSubjectOpen ? 'open' : ''}`}
            onClick={handleSubjectDropdownClick}
          >
            <div className="formselect-header">
              {selectedSubject ? `${selectedSubject} - ${selectedTitle || ''}` : 'Select Subject'}
              <span className="arrow">▼</span>
            </div>
            {isSubjectOpen && selectedClass && (
              <div className="dropdown-list">
                <div className="dropdown-scroll">
                  {dataset
                    .find(item => item.class === selectedClass)
                    ?.subjects.map(subject => (
                      <div 
                        key={subject.subject} 
                        className="subject-option"
                        onMouseEnter={(e) => {
                          setHoveredSubject(subject.subject);
                          const rect = e.currentTarget.getBoundingClientRect();
                          const titleOptions = e.currentTarget.querySelector('.title-options');
                          if (titleOptions) {
                            titleOptions.style.position = 'fixed';
                            titleOptions.style.top = `${rect.top+5}px`;
                            titleOptions.style.left = `${rect.left +102}px`;
                          }
                        }}
                        // onMouseLeave={handleSubjectLeave}
                      >
                        <div className="subject-name">
                          {subject.subject}
                          {subject.titles.length > 0 && <span className="arrow">▶</span>}
                        </div>
                        {subject.titles.length > 0 && (
                          <div className="title-options">
                            {subject.titles.map(title => (
                              <div
                                key={title.title}
                                className="title-option"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleTitleClick(subject.subject, title.title);
                                }}
                              >
                                {title.title}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dropdown-container">
          <div 
            className={`custom-select ${!selectedSubject ? 'disabled' : ''} ${showChapterSelect ? 'open' : ''}`}
            onClick={handleChapterFieldClick}
          >
            <div className="formselect-header">
              {selectedChapters.length > 0 ? (
                <div className="formchapter-pills">
                  {selectedChapters.slice(0, 2).map(chapter => (
                    <div 
                      key={chapter} 
                      className="formchapter-pill" 
                      title={chapter}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChapterChange(chapter);
                      }}
                    >
                      {chapter.length > 20 ? `${chapter.slice(0, 20)}...` : chapter}
                    </div>
                  ))}
                  {selectedChapters.length > 2 && (
                    <div 
                      className="show-more-wrapper"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="formchapter-pill show-more">
                        +{selectedChapters.length - 2} more
                      </div>
                      <div className="more-chapters-popup">
                        {selectedChapters.slice(2).map(chapter => (
                          <div 
                            key={chapter} 
                            className="popup-chapter"
                            onClick={() => handleChapterChange(chapter)}
                          >
                            {chapter}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <span>Select Chapters</span>
              )}
              <span className="arrow">▼</span>
            </div>
            {showChapterSelect && selectedSubject && (
              <div className="dropdown-list">
                
                  <div className="chapter-section">
                    <div className="chapter-list">
                    <div className="dropdown-scroll">
                      {dataset
                        .find(item => item.class === selectedClass)
                        ?.subjects.find(sub => sub.subject === selectedSubject)
                        ?.titles.find(t => t.title === selectedTitle)
                        ?.chapters.map(chapter => (
                          <div 
                            key={chapter} 
                            className={`chapter-item ${tempSelectedChapters.includes(chapter) ? 'selected' : ''}`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="checkbox"
                              id={chapter}
                              checked={tempSelectedChapters.includes(chapter)}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleChapterChange(chapter);
                              }}
                            />
                            <label 
                              htmlFor={chapter}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {chapter}
                            </label>
                          </div>
                        ))}
                    </div>
                    <div className="chapter-actions">
                      <button 
                        className="apply-button"
                        onClick={handleApplyChapters}
                        disabled={tempSelectedChapters.length === 0}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {isAssignment && (
          <div className="dropdown-container">
            <div 
              className={`custom-select ${isQTypeOpen ? 'open' : ''}`}
              onClick={handleQTypeClick}
            >
              <div className="formselect-header">
                {qType || 'Question Type'}
                <span className="arrow">▼</span>
              </div>
              {isQTypeOpen && (
                <div className="dropdown-list">
                  <div className="dropdown-scroll">
                    <div className="chapter-section">
                      <div className="chapter-list">
                        <div 
                          className={`chapter-item ${qType === 'Short Answers' ? 'selected' : ''}`}
                          onClick={() => setQType('Short Answers')}
                        >
                          Short Answers
                        </div>
                        <div 
                          className={`chapter-item ${qType === 'Long Answers' ? 'selected' : ''}`}
                          onClick={() => setQType('Long Answers')}
                        >
                          Long Answers
                        </div>
                        <div 
                          className={`chapter-item ${qType === 'Problem Solving' ? 'selected' : ''}`}
                          onClick={() => setQType('Problem Solving')}
                        >
                          Problem Solving
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="slider-section">
          <label>
            Difficulty
            <div className="formslider-container">
              <input
                type="range"
                min="0"
                max="2"
                step="1"
                value={difficulty === 'easy' ? 0 : difficulty === 'medium' ? 1 : 2}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  const values = ['easy', 'medium', 'hard'];
                  setDifficulty(values[value]);
                  const percent = (value / 2) * 100;
                  e.target.style.background = `linear-gradient(to right, #4a72ff 0%, #4a72ff ${percent}%, #e0e0e0 ${percent}%, #e0e0e0 100%)`;
                }}
                className="sideformslider difficulty-slider"
              />
              <div className="slider-ticks"></div>
              <div className="difficulty-labels">
                <span className={difficulty === 'easy' ? 'active' : ''}>Easy</span>
                <span className={difficulty === 'medium' ? 'active' : ''}>Medium</span>
                <span className={difficulty === 'hard' ? 'active' : ''}>Hard</span>
              </div>
            </div>
          </label>
        </div>

        <div className="slider-section">
          <label>
            No of Questions: {numQuestions}
            <div className="formslider-container">
              <input
                type="range"
                min="1"
                max="20"
                value={numQuestions}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setNumQuestions(value);
                  const percent = (value / 20) * 100;
                  e.target.style.background = `linear-gradient(to right, #4a72ff 0%, #4a72ff ${percent}%, #e0e0e0 ${percent}%, #e0e0e0 100%)`;
                }}
                className="sideformslider questions-slider"
              />
              <div className="slider-ticks"></div>
              <div className="slider-labels">
                <span>0</span>
                <span>5</span>
                <span>10</span>
                <span>15</span>
                <span>20</span>
              </div>
            </div>
          </label>
        </div>

        <div className="toggle-section">
          <label className="toggle-label">
            With Answers
            <div className="toggle-switch">
              <input
                type="checkbox"
                checked={showAnswers}
                onChange={(e) => {
                  setShowAnswers(e.target.checked);
                  if (onAnswersToggle) {
                    onAnswersToggle(e.target.checked);
                  }
                }}
                className="toggle-input"
              />
              <span className="toggle-slider"></span>
            </div>
          </label>
        </div>

        <button 
          className="generate-btn me-3"
          onClick={handleGenerateClick}
        >
          <img src="/images/rectangles.svg" alt="" />
          Generate
        </button>
      </div>
    </div>
  );
};

export default Formpage;  