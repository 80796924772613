import { useState, useEffect } from 'react';
import { baseEndpoint } from './API/endpoints';
import { MdHistory } from 'react-icons/md';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import './Leftmenu.css'
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ leftWidth: initialLeftWidth, onClick }) => {
  const navigate = useNavigate();
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [activityData, setActivityData] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [leftWidth, setLeftWidth] = useState(initialLeftWidth);
  const [isOpen, setIsOpen] = useState(initialLeftWidth === '15%');

  const toggleSidebar = () => {
    const newWidth = isOpen ? '5%' : '15%';
    setLeftWidth(newWidth);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (leftWidth === '15%') {
      setIsImageVisible(true);
    } else {
      setIsImageVisible(false);
    }
  }, [leftWidth]);

  const getStartOfWeek = (date) => {
    const resultDate = new Date(date);
    const day = resultDate.getDay();
    const diff = resultDate.getDate() - day; // Adjust when the day is Sunday
    return new Date(resultDate.setDate(diff));
  };
  const getDayDifference = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // Hours*Minutes*Seconds*Milliseconds
    return Math.round((date1 - date2) / oneDay);
  };
  const categorizeDate = (activityDate) => {
    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const startOfYesterday = new Date(startOfToday);
    startOfYesterday.setDate(startOfToday.getDate() - 1);
    const startOfWeek = getStartOfWeek(startOfToday);
    const startOfLastWeek = new Date(startOfWeek);
    startOfLastWeek.setDate(startOfWeek.getDate() - 7);
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

    const dateDifference = getDayDifference(new Date(startOfToday), new Date(activityDate));

    if (dateDifference === 0) return 'Today';
    if (dateDifference === 1) return 'Yesterday';
    if (new Date(activityDate) >= startOfWeek) return 'This Week';
    if (new Date(activityDate) >= startOfLastWeek) return 'Last Week';
    if (new Date(activityDate) >= startOfMonth) return 'This Month';
    if (new Date(activityDate) >= startOfLastMonth) return 'Last Month';
    return 'Older';
  };

  useEffect(() => {
    const fetchActivityData = async () => {
      try {
        const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'))
        const user_id = userDetail?.id;
        const response = await fetch(`${baseEndpoint}/user_access/users/${user_id}/response`);
        const data = await response.json();
        console.log('Fetched data:', data);
         // Ensure data is an array
         if (Array.isArray(data)) {
          setActivityData(data);
      } else {
          console.warn('Fetched data is not an array:', data);
          setActivityData([]); // Fallback to empty array
      }
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setActivityData([]);
        setLoading(false);
      }
    };

    // Fetch activity data on component mount
    fetchActivityData();

    // Set up an interval to refresh the activity data every 20 seconds
    const intervalId = setInterval(fetchActivityData, 20000); // 20 seconds

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const handleItemClick = (item) => {
    // Use the title to navigate to a dynamically generated path
    const titleSlug = item.content_type.replace(/\s+/g, '-').toLowerCase(); // Slugify title
    navigate(`/${titleSlug}`, { state: item }); // Send data to target page
  };

  return (
    <div 
      className="sidebar" 
      style={{ 
        width: leftWidth, 
        position: 'relative' 
      }}
    >
      <div className="sidebar-header" onClick={()=>{navigate('/');}}>
        <div className="logo-container">
          <img src="/images/Logo.svg" alt="Logo" />
        </div>

        {isImageVisible && <img src="/images/Group 10.svg" alt="Group 10" />}
      </div>

      <div 
        onClick={toggleSidebar}
        style={{
          position: 'absolute',
          top: '9%',
          right: '-10px',
          transform: 'translateY(-50%)',
          backgroundColor: '#2563EB',
          color: 'white',
          borderRadius: '50px',
          padding: '4px',
          cursor: 'pointer',
          width:'25px',
          height:'25px',
          display:'flex',
          alignItems:'center',
          fontSize:'22px',
          zIndex: 10,
        }}
      >
        {isOpen ? <FaAngleLeft /> : <FaAngleRight />}
      </div>

      <div 
        style={{
          height:'60%',
          overflow: 'hidden',
          transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}
      >
        {leftWidth === '15%' ? (
          <div style={{
            opacity: 1,
            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
            transform: 'translateX(0)',
            width: '90%',
            maxWidth: '300px'
          }}>
            <div style={{
              padding: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              // fontFamily: 'Plus Jakarta Sans',
              fontSize: '16px',
              fontWeight: '500',
              transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)'
            }}>
              <span style={{ 
                transition: 'opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                opacity: 1,
                fontWeight:'400',
                fontSize:'20px'
              }}>Recent Activity</span>
              <MdHistory 
                size={leftWidth === '15%' ? 28 : 24} 
                style={{ 
                  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  transform: 'rotate(0deg)',
                  flexShrink: 0
                }} 
              />
            </div>
            <div style={{ 
              padding: '12px',
              opacity: 1,
              transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
              overflowY:'auto',
            }}>
              {activityData?.map((item, index) => (
                <div key={index} 
                onClick={() => handleItemClick(item)} 
                style={{
                  marginBottom: '6px',
                  padding: '8px',
                  // fontFamily: 'Plus Jakarta Sans',
                  fontSize: '14px',
                  color: 'black',
                  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                  opacity: 1,
                  transform: 'translateX(0)',
                  textAlign: 'left',
                  cursor:'pointer',
                }}>
                  {item.content_type} for {item.file_name?.[0]}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div style={{
            padding: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
            width: '100%',
            paddingLeft: '0'
          }}>
            <MdHistory 
              size={leftWidth === '15%' ? 28 : 24} 
              style={{ 
                transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                transform: 'rotate(360deg)',
                flexShrink: 0
              }} 
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
