import React, { useState, useEffect, useRef } from 'react';
import './Mainform.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { baseEndpoint } from '../../API/endpoints';

// const basedata =[
//   {
//     "class": "6",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "Poorvi",
//             "chapters": [
//               "Culture and Tradition",
//               "Friendship",
//               "Change of Heart",
//               "Neem Baba",
//               "Fables and Folk Tales"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Curiosity",
//             "chapters": [
//               "Methods of Separation in Everyday Life",
//               "Beyond Earth",
//               "Mindful Eating: A Path to a Healthy Body",
//               "Materials Around Us",
//               "Living Creatures: Exploring their Characteristics",
//               "Exploring Magnets",
//               "A Journey through States of Water",
//               "The Wonderful World of Science",
//               "Nature’s Treasures",
//               "Measurement of Length and Motion",
//               "Temperature and its Measurement",
//               "Diversity in the Living World"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Exploring Society India and Beyond",
//             "chapters": [
//               "Grassroots Democracy – Part 1 Governance",
//               "The Beginnings of Indian Civilisation",
//               "Grassroots Democracy – Part 2 Local Government in Rural Areas",
//               "Timeline and Sources of History",
//               "Landforms and Life",
//               "The Value of Work",
//               "Locating Places on the Earth",
//               "Economic Activities Around Us",
//               "Unity in Diversity, or ‘Many in the One’",
//               "Grassroots Democracy – Part 3 Local Government in Urban Areas",
//               "India, That Is Bharat",
//               "Family and Community",
//               "Oceans and Continents",
//               "India’s Cultural Roots"
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "class": "7",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "Honey Comb",
//             "chapters": [
//               "Quality",
//               "Expert Detectives",
//               "Three Questions",
//               "Gopal and the Hilsa-fish",
//               "The Ashes That Made Trees Bloom",
//               "A Gift of Chappals",
//               "A Homage to our Brave Soldiers"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Science",
//             "chapters": [
//               "Motion and Time",
//               "Nutrition in Animals",
//               "Light",
//               "Heat",
//               "Physical and Chemical Changes",
//               "Nutrition in Plants",
//               "Respiration in Organisms",
//               "Forests: Our Lifeline",
//               "Acids, Bases and Salts",
//               "Electric Current and its Effects",
//               "Wastewater Story",
//               "Transportation in Animals and Plants",
//               "Reproduction in Plants"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Our Environment",
//             "chapters": [
//               "Inside our Earth",
//               "Environment",
//               "Human Environments  Interactions: The Tropical and  The Subtropical Region",
//               "Air",
//               "Life in the Desert",
//               "Water",
//               "Our Changing Earth"
//             ]
//           },
//           {
//             "title": "Our Pasts 2",
//             "chapters": [
//               "The Making of Regional Cultures",
//               "Delhi: 12th to 15th Century",
//               "Devotional Paths to the Divine",
//               "Introduction Tracing Changes Through a Thousand Years",
//               "Tribes , Nomads and Settled Communities",
//               "The Mughals (16th TO 17th Century)",
//               "Kings and Kingdoms",
//               "Eighteenth Century Political Formations"
//             ]
//           },
//           {
//             "title": "Social and Political Life 2",
//             "chapters": [
//               "On Equality",
//               "Growing up as Boys and Girls",
//               "How the State Government Works",
//               "A Shirt in the Market",
//               "Understanding Media",
//               "Role of the Government in Health",
//               "Market Around Us",
//               "Women Change the World"
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "class": "8",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "It so Happend",
//             "chapters": [
//               "The fight",
//               "The treasure within",
//               "Princess September",
//               "The Selfish Giant",
//               "Ancient Education System of India",
//               "Jalebis",
//               "Children at work",
//               "How the Camel got his hump"
//             ]
//           },
//           {
//             "title": "Honeydew",
//             "chapters": [
//               "A Visit to Cambridge",
//               "The Best Christmas Present in the World",
//               "A Short Monsoon Diary",
//               "Glimpses of the Past",
//               "This is Jody’s Fawn",
//               "The Summit Within",
//               "The Tsunami",
//               "Bepin Choudhury’s Lapse of Memory"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Science",
//             "chapters": [
//               "Chemical effects of Electric Current",
//               "Microorganisms : Friend and Foe",
//               "Combustion and Flame",
//               "Reaching the Age of Adolescence",
//               "Light",
//               "Sound",
//               "Reproduction in Animals",
//               "Crop Production and Management",
//               "Force and Pressure",
//               "Fiction",
//               "Conservation of Plants and Animals",
//               "Coal and Petroleum",
//               "Some Natural Phenomena"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Resource and Development",
//             "chapters": [
//               "Human Resources",
//               "Industries",
//               "Agriculture",
//               "Land, Soil, Water, Natural Vegetation and Wildlife Resources",
//               "Resources"
//             ]
//           },
//           {
//             "title": "Our Past 3",
//             "chapters": [
//               "Women, Caste and Reform",
//               "The Making of the National Movement: 1870s–1947",
//               "Tribals, Dikus and the Vision of a Golden Age",
//               "Ruling the Countryside",
//               "Introduction: How, When and Where",
//               "From Trade to Territory",
//               "When People Revolt",
//               "Civilising the “Native”, Educating the Nation"
//             ]
//           },
//           {
//             "title": "Social and  Political Life",
//             "chapters": [
//               "Confronting Marginalisation",
//               "Understanding Secularism",
//               "Law and Social Justice",
//               "Parliament and the Making of Laws",
//               "The Indian Constitution",
//               "Understanding Marginalisation",
//               "Judiciary",
//               "Public Facilities"
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "class": "9",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "Beehive",
//             "chapters": [
//               "If I Were You",
//               "Kathmandu",
//               "A Truly Beautiful Mind",
//               "The Snake and the Mirrror",
//               "The Little Girl",
//               "The Fun They Had",
//               "Reach for the Top",
//               "The Sound of Music",
//               "My Childhood"
//             ]
//           },
//           {
//             "title": "Moments Supplymentry Reader",
//             "chapters": [
//               "The Last Leaf",
//               "The Lost Child",
//               "The Adventures of Toto",
//               "The Happy Prince",
//               "Weathering the Storm in Ersama",
//               "A House Is Not a Home",
//               "The Beggar",
//               "Iswaran the Storyteller",
//               "In the Kingdom of Fools"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Information and Communication Technology",
//         "titles": [
//           {
//             "title": "Information and Communication Technology",
//             "chapters": [
//               "Getting Connected Internet",
//               "Fun with Logic",
//               "Creating Audio Video Communication",
//               "Creating Textual Communication",
//               "Creating Visual Communication",
//               "Presenting Ideas",
//               "Safety and Security in the Cyber World",
//               "Introduction to ICT"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Science",
//             "chapters": [
//               "Gravitation",
//               "Natural Resources",
//               "Improvement in Food Resources",
//               "Force and Laws of Motion",
//               "Sound",
//               "Atoms and Molecules",
//               "Structure of the Atom",
//               "Matter in our Surroundings",
//               "Is Matter around us Pure?",
//               "Tissues",
//               "Motion",
//               "Work and Energy",
//               "Why do we fall ill?",
//               "The Fundemental unit of Life"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Health and Physical Education",
//         "titles": [
//           {
//             "title": "Health and Physical Education",
//             "chapters": [
//               "Physical Education",
//               "Waste Management",
//               "Adolescent Health Services (AFHS)",
//               "Physical Fitness",
//               "Personality Development Through Yoga",
//               "Social Health",
//               "Growing up with Confidence",
//               "Diet for Healthy Living",
//               "Health and Diseases",
//               "Sports Training",
//               "Individual Sports",
//               "Team Games",
//               "First Aid and Safety",
//               "Ethics in Sports"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Economics",
//             "chapters": [
//               "Food Security in India",
//               "Poverty as a Challenge",
//               "The Story of Village Palampur",
//               "People as Resource"
//             ]
//           },
//           {
//             "title": "Democratic Politics",
//             "chapters": [
//               "Democratic Rights",
//               "Electoral Politics",
//               "Constitutional Design",
//               "What is Democracy? Why democracy?",
//               "Working  of Institutions"
//             ]
//           },
//           {
//             "title": "India and the Contemporary World 1",
//             "chapters": [
//               "Pastoralists in the Modern World",
//               "Socialism in Europe and the Russian Revolution",
//               "The French Revolution",
//               "Nazism and the Rise of  Hitler",
//               "Forest Society and Colonialism"
//             ]
//           },
//           {
//             "title": "Contemporary India",
//             "chapters": [
//               "India-Size and Location",
//               "Climate",
//               "Drainage",
//               "Natural Vegetation and Wildlife",
//               "Population",
//               "Physical Features of India"
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "class": "10",
//     "subjects": [
//       {
//         "subject": "English",
//         "titles": [
//           {
//             "title": "Footprints without Feet",
//             "chapters": [
//               "Footprints without Feet",
//               "A Question of Trust",
//               "A Triumph of Surgery",
//               "The Book That Saved the Earth",
//               "Bholi",
//               "The Thief's Story",
//               "The Necklace",
//               "The Making of a Scientist",
//               "The Midnight Visitor"
//             ]
//           },
//           {
//             "title": "First Flight",
//             "chapters": [
//               "The Proposal",
//               "Nelson Mandela: Long Walk to Freedom",
//               "A Letter to God",
//               "Madam Rides the Bus",
//               "Two Stories about Flying",
//               "From the Diary of Anne Frank",
//               "The Sermon at Benares",
//               "Glimpses of India",
//               "Mijbil the Otter"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Science",
//         "titles": [
//           {
//             "title": "Science",
//             "chapters": [
//               "Light – Reflection and Refraction",
//               "Chemical Reactions and Equations",
//               "How do Organisms Reproduce?",
//               "Our Environment",
//               "Metals and Non-metals",
//               "The Human Eye and the Colourful World",
//               "Acids, Bases and Salts",
//               "Control and Coordination",
//               "Electricity",
//               "Magnetic Effects of Electric Current",
//               "Carbon and its Compounds",
//               "Heredity",
//               "Life Processes"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Health and Physical Education",
//         "titles": [
//           {
//             "title": "Health and Physical Education",
//             "chapters": [
//               "Effects of Physical Activities on Human Body",
//               "Growth and Development during Adolescence",
//               "Team Games and Sports 2",
//               "Team Games and Sports 1",
//               "Social Health",
//               "Individual Games and Sports 1",
//               "Dietary Considerations and Food Quality",
//               "Yoga for Healthy Living",
//               "Physical Education : Relationship with other Subjects",
//               "Agencies and Awards Promoting Health,Sports and Yoga",
//               "Individual Games and Sports 2",
//               "Healthy Community Living",
//               "Safety Measures for Healthy Living"
//             ]
//           }
//         ]
//       },
//       {
//         "subject": "Social Science",
//         "titles": [
//           {
//             "title": "Democratic Politics",
//             "chapters": [
//               "Political Parties",
//               "Power-sharing",
//               "Gender, Religion and Caste",
//               "Outcomes of Democracy",
//               "Federalism"
//             ]
//           },
//           {
//             "title": "Understanding Economic Development",
//             "chapters": [
//               "Globalisation and The India Economy",
//               "Sectors of the  Indian Economy",
//               "Money and Credit",
//               "Consumer Rights",
//               "Development"
//             ]
//           },
//           {
//             "title": "India and the Contemporary World-2",
//             "chapters": [
//               "The Making of a Global World",
//               "The Age of Industrialisation",
//               "Nationalism in India",
//               "The Rise of Nationalism in Europe",
//               "Print Culture and the Modern World"
//             ]
//           },
//           {
//             "title": "Contemporary India",
//             "chapters": [
//               "Water Resources",
//               "Minerals and Energy Resources",
//               "Manufacturing Industries",
//               "Lifelines of National Economy",
//               "Agriculture",
//               "Resources and Development",
//               "Forest and Wildlife Resources"
//             ]
//           }
//         ]
//       }
//     ]
//   }
// ]

const Mainform = (path) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [formType, setFormType] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [isSubjectOpen, setIsSubjectOpen] = useState(false);
  const [isChapterOpen, setIsChapterOpen] = useState(false);
  const [isClassOpen, setIsClassOpen] = useState(false);
  const [hoveredSubject, setHoveredSubject] = useState(null);
  const [showAllChips, setShowAllChips] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState('10');
  const [difficulty, setDifficulty] = useState('medium');
  const CHIPS_TO_SHOW = 2;

  const subjectRef = useRef(null);
  const chapterRef = useRef(null);
  const classRef = useRef(null);

  const [basedata, setDataset] = useState([]);
  useEffect(() => {
    
    fetchDataFromBackend();
  }, []);
  const fetchDataFromBackend = async () => {
    try {
        // const response = await fetch('http://localhost:8080/dataset/dataset_access');
        const response = await fetch(`${baseEndpoint}/dataset/dataset_access`);
        const data = await response.json();
        console.log('Fetched data:', data); // Log the data to check its structure
        // setSelectedClass(data);
        setDataset(data);
        console.log(data)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  useEffect(() => {
    // If there's state passed from navigation, pre-fill the form
    if (location.state) {
      // Reset all form fields first
      setSelectedClass('');
      setSelectedSubject('');
      setSelectedTitle('');
      setSelectedChapters([]);
      setNumberOfQuestions('10');
      setDifficulty('medium');

      const { 
        selectedClass = '6', 
        selectedSubject = 'English', 
        selectedTitle = 'Poorvi', 
        selectedChapters = ['Culture and Tradition'],
        numberOfQuestions = '10',
        difficulty = 'medium',
        type 
      } = location.state;

      // Set state for class dropdown
      setSelectedClass(selectedClass);

      // Find and set the corresponding subject
      const classData = basedata.find(cls => cls.class === selectedClass);
      if (classData) {
        const subjectData = classData.subjects.find(sub => sub.subject === selectedSubject);
        if (subjectData) {
          setSelectedSubject(selectedSubject);
          
          // Find and set the corresponding title
          const titleData = subjectData.titles.find(title => title.title === selectedTitle);
          if (titleData) {
            setSelectedTitle(selectedTitle);
            
            // Set chapters
            setSelectedChapters(selectedChapters);
          }
        }
      }

      // Set number of questions and difficulty
      setNumberOfQuestions(numberOfQuestions);
      setDifficulty(difficulty);

      // Set form type for additional context
      setFormType(type || '');
    }
  }, [location.state]);

  useEffect(() => {
    // Extract form type from location state
    const { state } = location;
    if (state && state.type) {
      // Map the form type to match header dropdown exactly
      const formTypeMap = {
        'mcq': 'MCQ',
        'assignment': 'Assignment',
        'summarization': 'Summarization'
      };
      setFormType(formTypeMap[state.type] || state.type);
      
      // Reset form fields
      setSelectedClass('');
      setSelectedSubject('');
      setSelectedTitle('');
      setSelectedChapters([]);
      setNumberOfQuestions('10');
      setDifficulty('medium');
    }
  }, [location]);

  useEffect(() => {
    setShowAllChips(false);
  }, [selectedClass, selectedSubject, selectedTitle]);

  useEffect(() => {
    setSelectedClass('');
    setSelectedSubject('');
    setSelectedTitle('');
    setSelectedChapters([]);
    setNumberOfQuestions('10');
    setDifficulty('medium');
  }, [formType]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (subjectRef.current && !subjectRef.current.contains(event.target)) {
        setIsSubjectOpen(false);
        setHoveredSubject(null);
      }
      if (chapterRef.current && !chapterRef.current.contains(event.target)) {
        setIsChapterOpen(false);
      }
      if (classRef.current && !classRef.current.contains(event.target)) {
        setIsClassOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Get available classes from basedata
  const classes = basedata.map(item => item.class);

  // Get subjects with titles based on selected class
  const subjectsWithTitles = selectedClass
    ? basedata.find(item => item.class === selectedClass)?.subjects || []
    : [];

  // Get chapters based on selected subject and title
  const chapters = selectedClass && selectedSubject && selectedTitle
    ? basedata
        .find(item => item.class === selectedClass)
        ?.subjects.find(sub => sub.subject === selectedSubject)
        ?.titles.find(t => t.title === selectedTitle)
        ?.chapters || []
    : [];

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
    setSelectedSubject('');
    setSelectedTitle('');
    setSelectedChapters([]);
    setHoveredSubject(null);
  };

  const handleTitleSelect = (subject, title) => {
    setSelectedSubject(subject);
    setSelectedTitle(title);
    setSelectedChapters([]);
    setIsSubjectOpen(false);
  };

  const toggleChapter = (chapter) => {
    if (selectedChapters.includes(chapter)) {
      setSelectedChapters(selectedChapters.filter(ch => ch !== chapter));
    } else {
      setSelectedChapters([...selectedChapters, chapter]);
    }
  };

  const removeChapter = (chapter) => {
    setSelectedChapters(selectedChapters.filter(ch => ch !== chapter));
  };

  const getDisplayText = () => {
  
    if (!selectedTitle) return selectedSubject;
    return `${selectedSubject} - ${selectedTitle}`;
  };

  const handleSubmit = () => {
    const state = {
      selectedClass: selectedClass ,
      selectedSubject: selectedSubject,
      selectedTitle: selectedTitle ,
      selectedChapters: selectedChapters,
      numberOfQuestions: numberOfQuestions,
      difficulty: difficulty,
      autoSubmit: true
    };
    
    switch (formType) {
      case 'MCQ':
        navigate('/mcq', { state, replace: true });
        break;
      case 'Assignment':
        navigate('/assignment', { 
          state: {
            ...state,
            q_type: 'Short Answers'  // Add q_type for assignments
          }, 
          replace: true 
        });
        break;
      case 'Summarization':
        navigate('/summarization');
        break;
      default:
        break;
    }
  };

  return (
    <div className="mainformcontainer">
      <h1>{formType ? `${formType}` : 'Select Form Type'}</h1>
      <div className="form-container">
        <div className="dropdown-row">
          <div className="select-wrapper" ref={classRef}>
            <label className={`floating-label ${selectedClass ? 'float' : ''}`}>
              Select Class
            </label>
            <div 
              className={`select-header ${isClassOpen ? 'open' : ''} ${selectedClass ? 'has-value' : ''}`}
              onClick={() => setIsClassOpen(!isClassOpen)}
              style={{ paddingTop: selectedClass ? '24px' : '16px', paddingBottom: selectedClass ? '8px' : '16px' }}
            >
              <span className="select-text">{selectedClass ? `Class ${selectedClass}` : ''}</span>
              <span className="arrow-down"> <FaAngleDown /></span>
            </div>
            {isClassOpen && (
              <div className="select-options">
                {classes.map((cls) => (
                  <div
                    key={cls}
                    className={`select-option ${selectedClass === cls ? 'selected' : ''}`}
                    onClick={() => {
                      handleClassChange({ target: { value: cls } });
                      setIsClassOpen(false);
                    }}
                  >
                    <span className="option-text">Class {cls}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="select-wrapper" ref={subjectRef}>
            <label className={`floating-label ${selectedSubject ? 'float' : ''}`}>
              Subject 
            </label>
            <div 
              className={`select-header ${isSubjectOpen ? 'open' : ''} ${selectedSubject ? 'has-value' : ''}`}
              onClick={() => setIsSubjectOpen(!isSubjectOpen)}
              style={{ paddingTop: selectedSubject ? '24px' : '16px', paddingBottom: selectedSubject ? '8px' : '16px' }}
            >
              <span className="select-text">{getDisplayText()}</span>
              <span className="arrow-down"> <FaAngleDown /></span>
            </div>
            {isSubjectOpen && (
              <div className="select-options">
                {subjectsWithTitles.map((subject) => (
                  <div
                    key={subject.subject}
                    className={`select-option ${selectedSubject === subject.subject ? 'selected' : ''}`}
                    onMouseEnter={() => setHoveredSubject(subject.subject)}
                    onMouseLeave={() => setHoveredSubject(null)}
                  >
                    <span className="option-text">{subject.subject}</span>
                    <span className="arrow-right"> <FaAngleRight /></span>
                    {hoveredSubject === subject.subject && (
                      <div className="nested-select">
                        {subject.titles.map((title) => (
                          <div
                            key={title.title}
                            className={`nested-option ${
                              selectedSubject === subject.subject && 
                              selectedTitle === title.title ? 'selected' : ''
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTitleSelect(subject.subject, title.title);
                            }}
                          >
                            {title.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="mainformchapter-section">
          <div className="select-wrapper chapter-select" ref={chapterRef}>
            <label className={`floating-label ${selectedChapters.length > 0 ? 'float' : ''}`}>
              Chapter
            </label>
            <div 
              className={`select-header ${isChapterOpen ? 'open' : ''} ${selectedChapters.length > 0 ? 'has-value' : ''}`}
              onClick={() => setIsChapterOpen(!isChapterOpen)}
              style={{ paddingTop: selectedChapters.length > 0 ? '24px' : '16px', paddingBottom: selectedChapters.length > 0 ? '8px' : '16px' }}
            >
              <div className="header-content">
                {selectedChapters.length > 0 && (
                  <div className="header-chips">
                    {(showAllChips ? selectedChapters : selectedChapters.slice(0, CHIPS_TO_SHOW)).map((chapter) => (
                      <div key={chapter} className="header-chip" onClick={(e) => {
                        e.stopPropagation();
                        removeChapter(chapter);
                      }}>
                        <span className="chip-text">{chapter}</span>
                        <span className="chip-remove">×</span>
                      </div>
                    ))}
                    {!showAllChips && selectedChapters.length > CHIPS_TO_SHOW && (
                      <div 
                        className="header-chip show-more-chip"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAllChips(true);
                        }}
                      >
                        <span className="chip-showless">+{selectedChapters.length - CHIPS_TO_SHOW} more</span>
                      </div>
                    )}
                    {showAllChips && selectedChapters.length > CHIPS_TO_SHOW && (
                      <div 
                        className="header-chip show-less-chip"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAllChips(false);
                        }}
                      >
                        <span className="chip-showless">Show Less</span>
                      </div>
                    )}
                  </div>
                )}
                </div>
                <span className="arrow-down"> <FaAngleDown /></span>
            </div>
            {isChapterOpen && (
              <div className="select-options chapter-options">
                {chapters.map((chapter) => (
                  <div
                    key={chapter}
                    className={`select-option ${selectedChapters.includes(chapter) ? 'selected' : ''}`}
                    onClick={() => toggleChapter(chapter)}
                  >
                    <span className="option-text">{chapter}</span>
                    {selectedChapters.includes(chapter) && (
                      <span className="checkmark">✓</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <button 
          className="generate-btn"
          disabled={selectedChapters.length === 0}
          onClick={handleSubmit}
        >
          <img src="/images/rectangles.svg" alt="" />
          Generate
        </button>
      </div>
    </div>
  );
};

export default Mainform;