import React, { useEffect, useRef, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";

import './Header.css';
import { FaCaretDown } from "react-icons/fa";

const RightSection = ({ children, leftWidth, isMobileView, toggleLeftMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef(null);
  const userIconRef = useRef(null);  // Ref to the user icon
  const [selectedOption, setSelectedOption] = useState('');
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  // Mapping of routes to their corresponding dropdown options
  const routeToOptionMap = {
    '/mainform': location.state?.type || '',
    '/mcq': 'mcq',
    '/assignment': 'assignment',
    '/assignement': 'assignment',
    '/summarization': 'summarization'
  };

  useEffect(() => {
    // Determine selected option based on current route
    const currentRoute = location.pathname;
    const mappedOption = routeToOptionMap[currentRoute];
    
    // If a mapping exists, set the selected option
    if (mappedOption !== undefined) {
      setSelectedOption(mappedOption);
    } else {
      // Default to empty if no mapping found
      setSelectedOption('');
    }
  }, [location.pathname, location.state]);

  // Handle change in the select dropdown
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    switch (selectedValue) {
      case 'mcq':
        navigate('/mainform', { state: { type: selectedValue } });
        break;
      case 'assignment':
        navigate('/mainform', { state: { type: selectedValue } });
        break;
      case 'summarization': 
        navigate('/summarization');
        break;
      default:
        navigate('/');
        break;
    }
  };

  // Handle user sign out
  const handleSignOut = () => {
    localStorage.clear();
    navigate('/signin');
  };

  // Toggle user dropdown visibility
  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  // Close the dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (userIconRef.current && !userIconRef.current.contains(event.target)) {
      setIsUserDropdownOpen(false);
    }
  };

  // Add event listener to handle click outside the user icon
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div 
      ref={containerRef} 
      className="right-section" 
      style={{ 
        flex: 1, 
        marginLeft: leftWidth !== '5%' ? leftWidth : '0',
        transition: 'margin-left 0.4s ease'
      }}
    >
      {/* Header Section */}
      <div className="headheader">
        <div className="select-container">
          <select 
            className="Headercustom-select" 
            onChange={handleChange} 
            value={selectedOption}
          >
            <option value="">Select</option>
            <option value="mcq">MCQ</option>
            <option value="assignment">Assignment</option>
            <option value="summarization">Summarization</option>
          </select>
          <div className="select-arrow"><FaCaretDown /></div>
        </div>

        <div className="user-icon-container position-relative me-5" ref={userIconRef}>
          <div 
            className="user-icon d-flex align-items-center justify-content-center" 
            onClick={toggleUserDropdown}
            style={{ cursor: 'pointer' }}
          >
            <AiOutlineUser style={{ color: 'white', fontSize: '30px' }} />
          </div>
          {isUserDropdownOpen && (
            <div 
              className="user-dropdown position-absolute bg-white shadow rounded" 
              style={{ 
                top: '100%', 
                right: 0, 
                zIndex: 2000, 
                minWidth: '150px' 
              }}
            >
              <button 
                className="dropdown-item" 
                onClick={handleSignOut}
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Child Content Section */}
      <div className="content-section child-component">
        {children}
      </div>
    </div>
  );
};

export default RightSection;
