import React, { useEffect, useState, useRef, useId, useContext } from "react";
import { GrCopy } from "react-icons/gr";
import axios from "axios";
import jsPDF from "jspdf";
import { LuDownload } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import '.././Mcq.css'


import { useTheme } from '@mui/material/styles';
import { MdRefresh } from "react-icons/md";
import { baseEndpoint } from "../../API/endpoints";
import Formpage from "../Mainform/Rightsideform/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { CiBookmark } from "react-icons/ci";

const Assignments=()=>{
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const handleSwitchChange = (event) => {
        setShowAnswers(event.target.checked); // Update state based on switch
    };
    const anchorRef = useRef(null);
    const [showRecentActivity, setShowRecentActivity] = useState(true);
    
    const [questions, setQuestions] = useState([]);
    const [variantQuestions, setVariantQuestions] = useState([]);
    const [anchor, setAnchor] = React.useState(null);
    const [questionsHeading, setquestionsHeading] = useState(null);
    console.log(questions,'it will be ol')
    const [hasRefreshed, setHasRefreshed] = useState(false);
    const [assignmentResponseData, setassignmentResponseData] = useState([]);
    console.log(assignmentResponseData,'assignmentResponseData ].;;.;/.,;./,')
    const storeData = (id, userId, response) => {
        setassignmentResponseData(prevData => ({
          ...prevData,
          [`${id}_${userId}`]: {
            id,
            response,
            userId
          }
        }));
      };

      
      useEffect(() => {
        if (assignmentResponseData) {
            const keys = Object.keys(assignmentResponseData);
            if (keys.length > 0) {
                const lastKey = keys[keys.length - 1];
                const { userId, id } = assignmentResponseData[lastKey];
                setLastUserId(userId);
                setLastId(id);
            }
        }
    }, [assignmentResponseData]);

    const [loading, setLoading] = useState(false); 
    const [loading1, setLoading1] = useState(false); 

   

    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    // const handleChange = (event, newValue) => {
    //     // Ensure newValue is an array
    //     if (Array.isArray(newValue)) {
    //         setSelectedOptions(newValue);
    //     }
    // };

    // const handleInputChange = (event, newInputValue) => {
    //     setInputValue(newInputValue);
    // };

    // const handleDelete = (chipToDelete) => () => {
    //     setSelectedOptions((prevOptions) =>
    //         prevOptions.filter((option) => option !== chipToDelete)
    //     );
    // };


    // chips for topic
    const [showMoreChips, setShowMoreChips] = useState(false);

    const toggleShowMoreChips = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setShowMoreChips(!showMoreChips);
      };

      const [showMoreChips1, setShowMoreChips1] = useState(false);

    const toggleShowMoreChips1 = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setShowMoreChips1(!showMoreChips1);
      };

    
    const [classValue, setClassValue] = useState('');
    const [subjectValue, setSubjectValue] = useState('');
    const [chapterValues, setChapterValues] = useState([]);
    const [topicValues, setTopicValues] = useState([]);
    const [numberOfQuestions, setNumberOfQuestions] = useState(10); // Default value set to 10
    const [difficulty, setDifficulty] = useState('Medium'); 
    const [titleValue, setTitleValue] = useState('');
    const [classData, setClassData] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [titleOptions, setTitleOptions] = useState([]);
    const [chapterOptions, setChapterOptions] = useState([]);
    const [qtypeValue, setQtypeValue] = useState('short answers');
    const [lastUserId, setLastUserId] = useState(null);
    const [lastId, setLastId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const hasSubmitted = useRef(false);

    useEffect(() => {
        // Simulate fetching data from the backend
        fetchDataFromBackend();
      }, []);

      const fetchDataFromBackend = async () => {
        try {
            // const response = await fetch('http://localhost:8080/dataset/dataset_access');

            const response = await fetch(`${baseEndpoint}/dataset/dataset_access`)
            const data = await response.json();
            console.log('Fetched data:', data); // Log the data to check its structure
            setClassData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const qid =''
    console.log(qid," iasdhiofhaspdifhosdhYBUNR EBNI")

    // Convert the array to a string if needed
    const topicString = topicValues.join(', ');

    // Convert the string back to an array for the Autocomplete component
    const topicArrayForAutocomplete = topicString.split(',').filter(Boolean);
   
    const [formData, setFormData] = useState(null);
    const [sideFormData, setSideFormData] = useState({
        number: '10',
        complexity: 'medium'
    });
    const [hoveredQuestion, setHoveredQuestion] = useState(null);
    const [showDropdown, setShowDropdown] = useState(null);

    const handleQuestionAction = (action, groupIndex, questionIndex) => {
        switch(action) {
            case 'regenerate':
                // TODO: Implement regenerate functionality
                console.log('Regenerating question:', groupIndex, questionIndex);
                break;
            case 'delete':
                // TODO: Implement delete functionality
                console.log('Deleting question:', groupIndex, questionIndex);
                break;
            case 'report':
                // TODO: Implement report functionality
                console.log('Reporting question:', groupIndex, questionIndex);
                break;
            default:
                break;
        }
        setShowDropdown(null);
    };

    useEffect(() => {
        if (location.state) {
            const mainFormData = {
                standard: location.state.selectedClass,
                subject: location.state.selectedSubject,
                topic: '',
                file_name: location.state.selectedChapters,
                title: location.state.selectedTitle,
                q_type: location.state.q_type
            };
            setFormData(mainFormData);
        }
    }, [location.state]);
    const { 
        selectedClass, 
        selectedSubject, 
        selectedTitle, 
        selectedChapters, 
        numberOfQuestions: initQuestions, 
        difficulty: initDifficulty, 
        autoSubmit,
        q_type 
    } = location.state || {};

    const [showAnswers, setShowAnswers] = useState(false);
    const handleAnswersToggle = (checked) => {
        setShowAnswers(checked);
    };
    useEffect(() => {
        if (autoSubmit && !hasSubmitted.current && 
            selectedClass && selectedSubject && selectedTitle && 
            selectedChapters?.length > 0 && !loading) {
            hasSubmitted.current = true;
            handleSubmit({
                standard: selectedClass,
                subject: selectedSubject,
                topic: '',
                file_name: selectedChapters,
                number: initQuestions,
                complexity: initDifficulty,
                title: selectedTitle,
                q_type: q_type || 'Short Answers'
            });
        }
    }, [selectedClass, selectedSubject, selectedTitle, selectedChapters, loading]);

    const handleSubmit = async (formData) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        setLoading(true);
        setError(null);
        setSideFormData(formData);
        
        setQuestions([]);
        setassignmentResponseData([]); 
        const accessToken = localStorage.getItem('access_token');
        const googleToken = localStorage.getItem('token');
    
        if (!accessToken && !googleToken) {
            alert('Login to generate content');
            console.error('No access token or Google token found, redirecting to login...');
            window.location.href = '/signin';
            return;
        }
    
        const userDetail = JSON.parse(localStorage.getItem('userdetail')) || JSON.parse(localStorage.getItem('userdata'));
        const user_id = userDetail?.id;
    
        const endpoint = `${baseEndpoint}/assignment/${user_id}/assignment_stream`;
        const body = {
            standard: formData.standard,
            subject: formData.subject,
            topic: formData.topic || '',
            file_name: formData.file_name,
            number: formData.number,
            complexity: formData.complexity,
            title: formData.title,
            q_type: 'Short Answers'  // Set consistent q_type for assignments
        };
        setquestionsHeading(body);
        let currentQuestions = [];
        let responseData = [];
    
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let buffer = '';
            const stopIdentifier = '3e6c447a3f45797db5b4b66cc99c412ff27a7b62efcd8ba7c8b482add861ed5a3d89a2828be5d201e5d7bd4eddbff3a2c3a58d62ba6278be1e5bfcab377dc2fd';
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
    
                buffer += decoder.decode(value, { stream: true });
                console.log('Current buffer:', buffer);
    
                // Check for stop identifier in the buffer
                if (buffer.includes(stopIdentifier)) {
                    console.log('Stopping streaming due to identifier match.');
                    break;
                }
    
                // Process complete JSON objects in the buffer
                while (true) {
                    const startIndex = buffer.indexOf('{');
                    const endIndex = buffer.indexOf('}', startIndex) + 1;
    
                    if (startIndex === -1 || endIndex === -1) break;
    
                    const jsonString = buffer.slice(startIndex, endIndex);
                    buffer = buffer.slice(endIndex);
    
                    try {
                        const parsedData = JSON.parse(jsonString);
                        console.log(parsedData, 'Parsed data');
    
                        // Check if message is an error and should trigger a retry
                        if (parsedData.msg && parsedData.msg === "OpenAi could not generate response, Please Try again" && 3 > 0) {
                            console.log('Received retry message, retrying...');
                            setLoading(true);  // Reset loading state if retrying
                            await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
                            await handleSubmit(formData);  // Call the function again, decrementing retry count
                            return;  // Exit current function execution to avoid additional processing
                        }
    
                        // Only process valid question data
                        if (!parsedData.msg || parsedData.msg !== "OpenAi could not generate response, Please Try again") {
                            if (parsedData.userId) {
                                responseData.push(parsedData);
                                console.log(responseData, "All response data");
    
                                setassignmentResponseData(prevQuestions => {
                                    const newQuestions = [...prevQuestions];
    
                                    // Handle question grouping
                                    if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length > 0) {
                                        newQuestions.push([parsedData]);
                                    } else {
                                        newQuestions[newQuestions.length - 1].push(parsedData);
                                    }
    
                                    return newQuestions;
                                });
                            } else {
                                currentQuestions.push(parsedData);
                                console.log(currentQuestions, 'Current questions');
    
                                setLoading(false);
                                setQuestions(prevQuestions => {
                                    const newQuestions = [...prevQuestions];
    
                                    // Check if parsedData already exists in the existing chunks
                                    const isDuplicate = newQuestions.some(chunk => chunk.includes(parsedData));
    
                                    // If no duplicate found, add the parsedData
                                    if (!isDuplicate) {
                                        if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= formData.number) {
                                            newQuestions.push([parsedData]);
                                        } else {
                                            newQuestions[newQuestions.length - 1].push(parsedData);
                                        }
                                    }
    
                                    return newQuestions;
                                });
                            }
    
                            // If response contains stringified JSON in `response`, parse it
                            if (parsedData.response) {
                                const responseQuestions = JSON.parse(parsedData.response.replace(/'/g, '"'));
                                console.log(responseQuestions, 'Parsed response questions');
    
                                responseQuestions.forEach(question => {
                                    currentQuestions.push(question);
                                });
                            }
                        }
                    } catch (parseError) {
                        console.error('Error parsing JSON chunk:', parseError);
                        console.log('Malformed JSON string:', jsonString);
                    }
                }
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('An error occurred while fetching questions.');
        } finally {
            setLoading(false);
            setIsSubmitting(false);
        }
    };

    const renderQuestions = () => {
      return questions.map((group, index) => (
        <div key={index} className=" renderquestion d-flex aling-items-center justify-content-center">
          {/* <h3>Group {index + 1}</h3> */}
          <div className="rederbox1">
            {group.map((questionObj, qIndex) => (
            //   <p key={qIndex}>
            //     {qIndex+1}.{questionObj.question}
            //     <button
            //         onClick={(event) => handleRefreshQuestion(event,qIndex,index)}
            //         style={{
            //             backgroundColor: 'white',
            //             color: 'white',
            //             border: 'none',
            //             borderRadius: '5px',
            //             cursor: 'pointer',
            //             marginLeft: '10px', // Space between question and button
            //             marginRight:'10px'
            //         }}
            //     >
            //         <MdRefresh style={{ borderRadius: '50%', color: '#C03DD3', cursor: 'pointer', marginBottom: '4px' }} />
            //     </button>
            //     {showAnswers && <li style={{ fontWeight: '800' }}>Answer: {questionObj.answer}</li>}
            //   </p>
              <div 
              key={qIndex} 
              
              style={{
                  position: 'relative',
                  fontSize: '1rem',
                  fontWeight: '400',
                  padding: '10px',
                  borderRadius: '5px',
                 
              }}
          >
              <div 
              onMouseEnter={() => setHoveredQuestion(`${index}-${qIndex}`)}
              onMouseLeave={() => {
                  if (!showDropdown) {
                      setHoveredQuestion(null);
                  }
              }}
              style={{ display: 'flex',flexDirection:'column', alignItems: 'flex-start',  position: 'relative' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '100%' }}>
                      <span>{qIndex+1}.{questionObj.question}</span>
                      <div 
                          style={{ position: 'relative', display: 'inline-block', marginLeft: '10px' }}
                          onMouseEnter={(e) => {
                              setShowDropdown(`${index}-${qIndex}`);
                              const button = e.currentTarget.querySelector('button');
                              if (button) {
                                  button.style.backgroundColor = '#f5f5f5';
                                  const icon = button.querySelector('svg');
                                  if (icon) icon.style.color = '#666666';
                              }
                          }}
                          onMouseLeave={(e) => {
                              setShowDropdown(null);
                              const button = e.currentTarget.querySelector('button');
                              if (button) {
                                  button.style.backgroundColor = 'transparent';
                                  const icon = button.querySelector('svg');
                                  if (icon) icon.style.color = '#C0C0C0';
                              }
                          }}
                      >
                          <button
                              style={{
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  padding: '3px 6px',
                                  opacity: hoveredQuestion === `${index}-${qIndex}` ? 1 : 0,
                                  transition: 'all 0.2s ease',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                              }}
                          >
                              <BsThreeDotsVertical style={{ 
                                  color: '#C0C0C0',
                                  transition: 'color 0.2s ease',
                                  fontSize: '16px'
                              }} />
                          </button>
                          {showDropdown === `${index}-${qIndex}` && (
                              <div 
                                  style={{
                                      position: 'absolute',
                                      left: '100%',
                                      top: '0',
                                      backgroundColor: 'white',
                                      boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                      borderRadius: '5px',
                                      zIndex: 1000,
                                      minWidth: '180px',
                                      border: '1px solid #eee'
                                  }}
                              >
                                  <div 
                                      onClick={(event) => handleRefreshQuestion(event, qIndex,index)}
                                      style={{ 
                                          padding: '8px 15px', 
                                          cursor: 'pointer',
                                          borderBottom: '1px solid #eee',
                                          transition: 'background-color 0.2s',
                                          ':hover': { backgroundColor: '#f5f5f5' }
                                      }}
                                      onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                      onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                  >
                                      Regenerate Question
                                  </div>
                                  <div 
                                      onClick={() => handleQuestionAction('delete', index, qIndex)}
                                      style={{ 
                                          padding: '8px 15px', 
                                          cursor: 'pointer',
                                          borderBottom: '1px solid #eee',
                                          transition: 'background-color 0.2s'
                                      }}
                                      onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                      onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                  >
                                      Delete Question
                                  </div>
                                  <div 
                                      onClick={() => handleQuestionAction('report', index, qIndex)}
                                      style={{ 
                                          padding: '8px 15px', 
                                          cursor: 'pointer',
                                          transition: 'background-color 0.2s'
                                      }}
                                      onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                                      onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                                  >
                                      Report Question
                                  </div>
                              </div>
                          )}
                      </div>
                  </div>
                  
                  {showAnswers && <li style={{ fontWeight: '800' ,maxWidth: '100%' }}>Answer: {questionObj.answer}</li>}
              </div>
          </div>
            ))}
          </div>
        </div>
      ));
    };
    
    const handleRefreshQuestion = async (event, index,mcid) => {
      event.preventDefault();
      const accessToken = localStorage.getItem('access_token');
      const googleToken = localStorage.getItem('token');
  
      if (!accessToken && !googleToken) {
          alert('Login to generate content');
          console.error('No access token or Google token found, redirecting to login...');
          window.location.href = '/login';
          return;
      }
  
      const item = assignmentResponseData[mcid][0]; // Assuming you want the first item
      console.log(item,"it the all data to get usid rid")
      const { userId, id } = item; // Destructure userId and id

      console.log("User ID:",item.userId);
      console.log("ID:", item.id);
    //   const endpoint = `http://localhost:8080/editable/users/${userId}/responses/${id}/edit`;
      const endpoint = `${baseEndpoint}/editable/users/${userId}/responses/${id}/edit `
  
      const method = 'POST';
      const sendingdata = {
          index_number: index,
      };
  
      try {
          const response = await axios({
              method: method,
              url: endpoint,
              data: sendingdata,
          });
  
          console.log(response.data, "Fetched single question data");
  
  
          // Check if the fetched data matches the current mcqResponseData
          // if (assignmentResponseData[key]) {
          //     const newQuestionData = response.data.response; // Adjust based on your API response structure
  
          //     // Update the state to replace the specific question
          //     setassignmentResponseData(prevData => ({
          //         ...prevData,
          //         [key]: {
          //             ...prevData[key],
          //             response: prevData[key].response.map((item, idx) => 
          //                 idx === index ? { ...item, ...newQuestionData } : item // Replace the question at the given index
          //             )
          //         }
          //     }));
          // } else {
          //     console.error("ID and User ID do not match.");
          // }
          if (questions[mcid]) {
            const newQuestionData = response.data.response; // This should be the new question object
        
            // Update the state to replace the specific question
            setQuestions(prevQuestions => {
                return prevQuestions.map((innerArray, innerIndex) => {
                    // Check if we are in the right inner array
                    if (innerIndex === mcid) {
                        return innerArray.map((question, questionIndex) => {
                            // Replace the specific question object at questionIndex
                            if (questionIndex === index) {
                                return { ...question, ...newQuestionData }; // Replace with new data
                            }
                            return question; // Keep the existing question
                        });
                    }
                    return innerArray; // Keep the existing inner array
                });
            });
        } else {
            console.error("ID and User ID do not match.");
        }
      } catch (error) { 
          console.error('Error fetching data:', error);
      } finally {
          setLoading(false); 
      }
  };
  
  
const handleVariant = async (event,retryCount = 3) => {
    event.preventDefault();
    setLoading1(true)
    const accessToken = localStorage.getItem('access_token');
    const googleToken = localStorage.getItem('token'); // assuming this is where you store Google token
  
    if (!accessToken && !googleToken) {
      alert('Login to generate content');
      console.error('No access token or Google token found, redirecting to login...');
      window.location.href = '/login';  // Replace with your login page route
      return;
    }
  
    const lastindex = assignmentResponseData.length - 1;
    console.log(lastindex, "It is to variant generation");
    const item = assignmentResponseData[lastindex][0];
    const user_id = item.userId;
    const response_id = item.id;
  
    // const endpoint = `http://localhost:8080/assignment/users/${user_id}/responses/${response_id}/variants_stream`;
    const endpoint =  `${baseEndpoint}/assignment/users/${user_id}/responses/${response_id}/variants_stream`;
    const method = 'POST';
    let sendingData;
    if (sideFormData) {
        sendingData = {
            standard: sideFormData.standard,
            subject: sideFormData.subject,
            topic: sideFormData.topic || '',
            file_name: sideFormData.file_name,
            title: sideFormData.title,
            number: sideFormData.number,
            complexity: sideFormData.complexity,
            q_type:sideFormData.q_type,
        };
        
    } else if ( formData) {
        // If main form data is available, use it as base
        sendingData = {
            standard: formData.standard,
            subject: formData.subject,
            topic: formData.topic || '',
            file_name: formData.file_name,
            title: formData.title,
            number:10,
            complexity: 'medium'
        };
    } else {
        console.error('No form data available');
        setLoading1(false);
        return;
    }
    let currentQuestions = []; // Questions being streamed
    let responseData = []; // All parsed responses
    let buffer = ''; // Buffer to accumulate stream data
    const stopIdentifier = '3e6c447a3f45797db5b4b66cc99c412ff27a7b62efcd8ba7c8b482add861ed5a3d89a2828be5d201e5d7bd4eddbff3a2c3a58d62ba6278be1e5bfcab377dc2fd';
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendingData),
      });
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        buffer += decoder.decode(value, { stream: true });
        console.log('Current buffer:', buffer);
  
        // Check for stop identifier to stop reading
        if (buffer.includes(stopIdentifier)) {
          console.log('Stopping streaming due to identifier match.');
          break;
        }
  
        // Process the buffer for complete JSON objects
        while (true) {
          const startIndex = buffer.indexOf('{');
          const endIndex = buffer.indexOf('}', startIndex) + 1;
  
          if (startIndex === -1 || endIndex === -1) break;
  
          const jsonString = buffer.slice(startIndex, endIndex);
          buffer = buffer.slice(endIndex); // Remove processed part from buffer
  
          try {
            const parsedData = JSON.parse(jsonString);
            console.log(parsedData, 'Parsed data');

            if (parsedData.msg && parsedData.msg === "OpenAi could not generate response, Please Try again" && retryCount > 0) {
                console.log('Received retry message, retrying...');
                setLoading1(true);  // Reset loading state if retrying
                await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
                await handleVariant(event, retryCount - 1);  // Call the function again, decrementing retry count
                return;  // Exit current function execution to avoid additional processing
            }
            if (!parsedData.msg || parsedData.msg !== "OpenAi could not generate response, Please Try again") {
                // Check if it's a response (should not be duplicated)
                if (parsedData.userId) {
                responseData.push(parsedData);
                console.log(responseData, "All response data");
    
                setassignmentResponseData(prevQuestions => {
                    const newQuestions = [...prevQuestions];
    
                    // Group questions properly
                    if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length > 0) {
                    newQuestions.push([parsedData]);
                    } else {
                    newQuestions[newQuestions.length - 1].push(parsedData);
                    }
    
                    return newQuestions;
                });
                } else {
                // Only add the question if it's not a duplicate
                
                    currentQuestions.push(parsedData);
                    console.log(currentQuestions, 'Current questions');
    
                    setLoading1(false);
                    setQuestions(prevQuestions => {
                        const newQuestions = [...prevQuestions];
                        
                        // Check if parsedData already exists in the existing chunks
                        const isDuplicate = newQuestions.some(chunk => chunk.includes(parsedData));
                    
                        // If no duplicate found, add the parsedData
                        if (!isDuplicate) {
                        if (newQuestions.length === 0 || newQuestions[newQuestions.length - 1].length >= numberOfQuestions) {
                            newQuestions.push([parsedData]);
                        } else {
                            newQuestions[newQuestions.length - 1].push(parsedData);
                        }
                        }
                    
                        return newQuestions;
                    });
                    
                }
    
                // Handle response questions (stringified JSON inside `response`)
                if (parsedData.response) {
                const responseQuestions = JSON.parse(parsedData.response.replace(/'/g, '"'));
                console.log(responseQuestions, 'Parsed response questions');
    
                responseQuestions.forEach(question => {
                    const isDuplicate = currentQuestions.some(q => q.id === question.id);
                    if (!isDuplicate) {
                    currentQuestions.push(question);
                    }
                });
                }
            }
          } catch (parseError) {
            console.error('Error parsing JSON chunk:', parseError);
            console.log('Malformed JSON string:', jsonString);
          }
        }
      }
  
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading1(false);
    }
  };
  

  
  
  const generatePDF = (questionsData, title) => {
    const doc = new jsPDF();
    const addWatermark = () => {
        doc.setFontSize(50);
        doc.setFont("helvetica", "bold");
    
        // Set a gray color for the watermark and reduce opacity (alpha blending)
        doc.setTextColor(175, 175, 175); // Gray color (RGB) for watermark
        
        // Get the page dimensions
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
    
        // Rotate the text to place it diagonally
        doc.text("Transpoze", pageWidth / 2, pageHeight / 2, {
            angle: 45,
            align: "center"
        });
    
        // Reset text color after watermark is added
        doc.setTextColor(0, 0, 0); // Reset text color to black
    };

    const centerTextWithUnderline = (text, y) => {
        const pageWidth = doc.internal.pageSize.width;
        const textWidth = doc.getStringUnitWidth(text) * doc.internal.scaleFactor;
        const x = (pageWidth - (textWidth * 2)) / 2;

        doc.text(text, x, y);
        const lineY = y + 2; 
        doc.line(x, lineY, x + textWidth * 2.3, lineY);
        doc.line(x, lineY + 1, x + textWidth * 2.3, lineY + 1);
    };

    const addQuestionsPage = (questions, startY, pageNumber) => {
        let y = startY;
        questions.forEach((item, index) => {
            doc.setFontSize(14);
            doc.setFont("helvetica", "bold"); // Set font to bold for questions

            const questionText = (index + 1) + '. ' + item.question;
            const questionLines = doc.splitTextToSize(questionText, 150);
            const questionHeight = questionLines.length * 10;

            if (y + questionHeight > 250) {
                addPageFooter(pageNumber); 
                doc.addPage();
                pageNumber++;
                addWatermark();
                y = 20; 
                doc.setFontSize(16);
                y += 10; 
            }

            questionLines.forEach(line => {
                doc.text(line, 10, y);
                y += 10;
            });
        });
        return pageNumber; 
    };

    const addPageFooter = (pageNumber) => {
        const pageText = `Page ${pageNumber}`;
        doc.setFontSize(10);
        const pageWidth = doc.internal.pageSize.width;
        const textWidth = doc.getStringUnitWidth(pageText) * doc.internal.scaleFactor;
        const x = (pageWidth - textWidth) / 2;
        doc.text(pageText, x, 290); 
    };

    let y = 30; 
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    centerTextWithUnderline(`Class ${title.standard} - ${title.subject}`, 10);

    const topicText = title.subtopic ? `(${title.subtopic})` : '';
    if (topicText) {
        doc.setFont("helvetica", "italic");
        const topicLines = doc.splitTextToSize(topicText, doc.internal.pageSize.width - 20); 

        topicLines.forEach((line, index) => {
            const topicWidth = doc.getStringUnitWidth(line) * doc.internal.scaleFactor;
            const topicX = (doc.internal.pageSize.width - topicWidth * 2.5) / 2; 
            doc.text(line, topicX, 20 + (index * 10));
        });

        doc.setFont("helvetica", "normal");
        y = 20 + (topicLines.length * 10) + 10; 
    }

    addWatermark();

    doc.setFontSize(16);
    doc.text('Questions', 10, y);
    y += 10; 
    let pageNumber = 1;
    pageNumber = addQuestionsPage(questionsData, y, pageNumber); 

    addPageFooter(pageNumber); 

    // Add Answers
    doc.addPage();
    pageNumber++;
    addWatermark();
    doc.setFontSize(18);
    doc.text('Answers', 10, 10);
    doc.setFontSize(12);
    y = 20; // Reset y for answers page

    doc.setFont("helvetica", "normal"); // Set font to normal for answers

    questionsData.forEach((item, index) => {
        const answerText = (index + 1) + ') ' + item.answer;
        const answerLines = doc.splitTextToSize(answerText, 190);
        answerLines.forEach((line, lineIndex) => {
            doc.text(line, 10, y + (lineIndex * 10));
        });
        y += answerLines.length * 10 + 5; // Move down after each answer
    });

    addPageFooter(pageNumber);
    doc.save(`class_${title.standard}-${title.subject}.pdf`);
};


    
    
      const contentRef = useRef(null);
    
    // const copyToClipboard = (key) => {
    //   const { response } = assignmentResponseData[key];
    //   const responseData = response.map((item, index) => {
    //       return `${index + 1}. ${item.question}\n\nAnswer: ${item.answer}`;
    //   }).join('\n\n');
    
    //   navigator.clipboard.writeText(responseData)
    //       .then(() => {
    //           alert('Response copied to clipboard!');
    //           console.log(key,"itiskey in copycode")
    //       })
    //       .catch(err => {
    //           console.error('Failed to copy: ', err);
    //       });
    // };

    const copyToClipboard = (key) => {
      const selectedQuestions = questions[key]; // Access the specific inner array based on the key
  
      if (!selectedQuestions || selectedQuestions.length === 0) {
          alert('No questions found for this key!');
          return;
      }
  
      const responseData = selectedQuestions.map((item, index) => {
          return `${index + 1}. ${item.question}\nAnswer: ${item.answer}`;
      }).join('\n\n');
  
      navigator.clipboard.writeText(responseData)
          .then(() => {
              alert('Response copied to clipboard!');
              console.log(key, "is the key in copy code");
          })
          .catch(err => {
              console.error('Failed to copy: ', err);
          });
    };
    const handleClearResponse = (e) => {
        e.preventDefault();
        setQuestions([]); // Clear the questions array
        setassignmentResponseData([]); // Clear the response data
        setquestionsHeading(null); // Reset the heading
    };

    const itemData = location.state;
    useEffect(() => {
        if (itemData && itemData.response) {
          console.log(itemData, "it is the data from the history");
          setQuestions([itemData.response]); // Set questions only if itemData.response exists
          setassignmentResponseData([itemData]);    // Set mcqResponseData only if itemData exists
        }
      }, [itemData]);
    return(
        <div className="maincontainer">
        <div className="main-content">
            <div className="header">
                <div className="header-left">
                    <div className="icon-container">
                        <div className="icon-circle">
                            <img
                                src="/images/rectangles.svg"
                                alt=""
                                className="icon-img"
                            />
                        </div>
                        <p className="header-text">
                        {questionsHeading ? `${questionsHeading.number} Assignment Questions on ${questionsHeading.subject} for Class ${questionsHeading.standard} (${questionsHeading.complexity.charAt(0).toUpperCase() + questionsHeading.complexity.slice(1)}  Difficulty)` : 'Assignemnet Questions'}
                        </p>
                    </div>
                    <a href="#" onClick={handleClearResponse} className="clear-response">Clear Response</a>
                </div>
            </div>

            <div className="questions-container" ref={contentRef}>
                    {loading ? (
                        <div style={{width:'100%',height:"100%",display:'flex',alignItems:'center',justifyContent:'center',paddingRight:'20%'}}>
                            <img src="/images/Animation-2.gif" alt="" style={{width:'50px',height:'80px'}} />
                        </div>
                    ) : (
                        renderQuestions()
                    )}
                </div>

            <div className="footer">
                <div className="footer-left">
                    <b onClick={() => copyToClipboard(assignmentResponseData.length-1)} className="footer-icon">
                        <GrCopy />
                    </b>
                    <b onClick={() => generatePDF(questions[assignmentResponseData.length-1], questionsHeading)} className="footer-icon">
                        <LuDownload />
                    </b>
                </div>
                <button
                    onClick={(event) => handleVariant(event)}
                    className="regenerate-button"
                    disabled={!assignmentResponseData || assignmentResponseData.length === 0}
                >
                    <img src='/images/Vector.svg' className="button-icon" />
                    Regenerate response
                </button>
            </div>
        </div>

        <div className="formbase">
            <Formpage 
                onAnswersToggle={handleAnswersToggle}
                onFormSubmit={handleSubmit}
            />
        </div>
    </div>
    );
};
export default Assignments;