import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Main.css"; // Import the CSS file

const Main = () => {
  const navigate = useNavigate();
  const [greeting, setGreeting] = useState('Good Morning');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    // Determine greeting based on time of day
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour < 17) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }

    // Check if user is logged in
    const userDetail = localStorage.getItem('userdetail')||localStorage.getItem('userdata');
    if (userDetail) {
      try {
        const parsedUserDetail = JSON.parse(userDetail);
        const userName = parsedUserDetail.full_name || parsedUserDetail.name;
        if (userName) {
          setUserName(userName);
        }
      } catch (error) {
        // Silent error handling
      }
    }
  }, []);

  const handleCardClick = (type) => {
    navigate(`/mainform`, { 
      state: { 
        type: type 
      } 
    });
  };

  return (
    <div className="main-container">
      <div className="inner-container">
        <p className="text-gradient">Guru Dhevo Bhava</p>
        <p className="main-title">
          {/* {greeting}{userName ? `, ${userName}` : ''} */}
          {greeting}{userName ? `, ${userName.charAt(0).toUpperCase() + userName.slice(1)}` : ''}
        </p>

        <div className="cards-container">
          {/* Repeat this block for each card */}
          <div className="card ">
            <div className="card-icon">
              <img src="/images/rectangles.svg" alt="" />
            </div>
            <p className="card-description">
              Generate Multiple Choice Questions with ease
            </p>
            <button 
              className="card-button" 
              onClick={() => handleCardClick('mcq')}
            >
              <p className="button-text">MCQ</p>
            </button>
          </div>
          {/* Repeat the card component */}
          <div className="card ">
            <div className="card-icon">
              <img src="/images/rectangles.svg" alt="" />
            </div>
            <p className="card-description">
              Create Comprehensive Assignment Questions
            </p>
            <button 
              className="card-button" 
              onClick={() => handleCardClick('assignment')}
            >
              <p className="button-text">Assignment</p>
            </button>
          </div>
{/* Repeat the card component */}
          <div className="card ">
            <div className="card-icon">
              <img src="/images/rectangles.svg" alt="" />
            </div>
            <p className="card-description">
              Generate Concise Content Summaries
            </p>
            <button 
              className="card-button" 
              onClick={() => handleCardClick('summarization')}
            >
              <p className="button-text">Summarization</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
